import * as React from 'react';
import {AsyncStorage, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import {useMediaQuery} from 'react-responsive'
import {ActivityIndicator, Snackbar} from "react-native-paper";

// Local
import {Colors, Measurements} from '../constants';
import Button from '../components/Button';
import {CartContext} from "../context";
import Footer from "../components/Footer";
import {wcAPI} from "../apis/WPREST";
import {sanitizeHtml} from "../utils";
import Page from "../components/Page";

function Header({categories}) {
	const isTabletOrMobile = useMediaQuery({query: `(max-width: ${Measurements.tabletBreakPt}px)`});
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			paddingVertical: 14,
			backgroundColor: Colors.primary,
		},
		scrollViewContainer: {
			width: '100%',
			justifyContent: 'center',
		},
		link: {
			paddingHorizontal: 16,
		},
		linkText: {
			color: '#ffffff',
			fontWeight: isTabletOrMobile ? '500' : '600',
			fontSize: isTabletOrMobile ? 13 : 15,
		}
	});
	if (!categories) return null;
	return (
			<View style={styles.container}>
				<ScrollView contentContainerStyle={styles.scrollViewContainer} horizontal={true}>
				{categories.map(category => (
					<View
						key={category.slug}
						style={styles.link}>
						<a
							href={`#${category.slug}`}
							style={{textDecoration: 'none'}}>
							<Text style={styles.linkText}>{category.name.toUpperCase()}</Text>
						</a>
					</View>
				))}
				<View
					style={styles.link}>
					<a
						href={`#footer`}
						style={{textDecoration: 'none'}}>
						<Text style={styles.linkText}>CONTACT</Text>
					</a>
				</View>
				<View
					style={styles.link}>
					<a
						href={`#footer`}
						style={{textDecoration: 'none'}}>
						<Text style={styles.linkText}>SUPPORT</Text>
					</a>
				</View>
				</ScrollView>
			</View>
	)
}

function Product(props) {
	const {cart = [], description, id, images, name, salePrice, regPrice, price, right = false, updateCart, showAlert} = props;
	const isTabletOrMobile = useMediaQuery({query: `(max-width: ${Measurements.tabletBreakPt}px)`});
	let isRight = right;
	if (isTabletOrMobile) isRight = false;
	const image = {uri: images[0].src};
	const styles = StyleSheet.create({
		container: {
			minHeight: 800,
			width: '100%',
			maxWidth: 1024,
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			flexWrap: 'wrap',
		},
		image: {
			height: isTabletOrMobile ? 400 : 720,
			width: isTabletOrMobile ? '100%' : 720,
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			borderWidth: 1,
			borderColor: '#e0e0e0',
			borderRadius: 4,
		},
		info: {
			minHeight: 400,
			minWidth: 304,
			flex: 1,
			paddingHorizontal: 24,
		},
		name: {
			fontSize: 32,
			color: '#656565'
		},
		price: {
			fontSize: 18,
			fontWeight: '500',
			marginTop: 6,
		},
		regPrice: {
			textDecorationLine: 'line-through',
			textDecorationColor: Colors.primary,
			color: Colors.primary
		},
		description: {
			fontSize: 15,
			color: '#555555',
			marginTop: 16,
			marginBottom: 28,
			lineHeight: 24,
		},
	});
	return (
		<View style={styles.container}>
			{!isRight && <Image style={styles.image} resizeMode={'contain'} source={image}/>}
			<View style={styles.info}>
				<Text style={styles.name}>{name}</Text>
				<Text style={styles.price}>
					BBD ${Number(price).toFixed(2)}
					<Text>{'  '}</Text>
					<Text style={styles.regPrice}>{salePrice ? regPrice : ''}</Text>
				</Text>
				<Text style={styles.description}>{sanitizeHtml(description)}</Text>
				<Button onPress={() => {
					const newCart = [...cart];
					let hasItem = false;
					let newItem = {name, price, id, image, quantity: 1};
					for (let i = 0; i < cart.length; i++) {
						const item = cart[i];
						if (item.id === id) {
							hasItem = true;
							newItem = {...item, quantity: item.quantity + 1};
							newCart[i] = newItem;
							break;
						}
					}
					
					if (!hasItem) {
						newCart.push(newItem);
					}
					
					AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(() => {
						updateCart({type: 'init', payload: {cart: newCart}});
						//alert(`${name} has been added to your cart. Close to continue shopping`);
						showAlert();
					});
				}}>Add to cart</Button>
			</View>
			{isRight && <Image style={styles.image} resizeMode={'contain'} source={image}/>}
		</View>
	)
}

export default function Home({navigation}) {
	const cartContext = React.useContext(CartContext);
	const [wcProducts, setWcProducts] = React.useState(null);
	const [wcCategories, setWcCategories] = React.useState(null);
	const [categoryProducts, setCategoryProducts] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [isSnackbarVisible, showSnackbar] = React.useState(false);
	React.useEffect(()=> {
		if (wcCategories === null){
			fetch(wcAPI.categories)
				.then(results => results.json())
				.then(data => {
					if (!data?.data?.status) {
						const cats = [];
						data.forEach(category => {
							if (category.slug === 'electric-cars') cats.unshift(category);
							else if (category.slug !== 'uncategorized') cats.push(category);
						});
						setWcCategories(cats);
					}
				});
		}
	}, [wcCategories]);
	
	React.useEffect(()=> {
		if (wcProducts === null){
			fetch(wcAPI.products)
				.then(results => results.json())
				.then(data => {
					if (!data?.data?.status) setWcProducts(data)
				});
		}
	}, [wcProducts]);
	
	React.useEffect(()=> {
		const catProds = [];
		if (wcProducts !== null && wcCategories !== null){
			wcCategories.forEach(category => {
				const prods = [];
				wcProducts.forEach(product => {
					for (let i = 0; i< product.categories.length; i++){
						const curCat = product.categories[i];
						if (curCat.id === category.id){
							prods.push(product);
							break;
						}
					}
				});
				catProds.push({
					name: category.name,
					slug: category.slug,
					id: category.id,
					products: prods
				})
			});
			setCategoryProducts(catProds);
			setLoading(false);
		}
	}, [wcProducts, wcCategories]);
	
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
		},
		banner: {
			width: '100%',
			height: 88,
			backgroundColor: Colors.accent,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: 24,
			flexWrap: 'wrap'
		},
		bannerText: {
			color: 'white',
			fontSize: 28,
			fontWeight: '300'
		},
		indicator: {
			marginVertical: 200,
		}
	});
	return (
		<Page>
			<View style={styles.container}>
				<Header categories={categoryProducts}/>
				<ScrollView>
					{loading && <ActivityIndicator size={'large'} style={styles.indicator}/>}
					{!loading && (
						<View style={styles.banner}>
							<Text style={styles.bannerText}>40% off cars, financing available</Text>
						</View>
					)}
					{!loading && categoryProducts.map((category, index) => (
						<div
							key={category.slug}
							id={category.slug}
							style={{
								minHeight: 800,
								width: '100%',
								paddingTop: 16,
								paddingBottom: 16,
								borderBottom: "1px solid #e9e9e9",
							}}>
							{category.products.map((product, index) => (
								<Product
									showAlert={() => showSnackbar(true)}
									key={product.id}
									{...product}
									salePrice={product['sale_price']}
									regPrice={product['regular_price']}
									description={product['short_description']}
									right={index % 2 == 0}
									cart={cartContext.cart}
									updateCart={cartContext.updateCart}/>
							))}
						</div>
					))}
					<div id={'footer'}>
						<Footer/>
					</div>
				</ScrollView>
				<Snackbar
					visible={isSnackbarVisible}
					onDismiss={() => showSnackbar(false)}
					action={{
						label: 'Go to cart',
						onPress: () => {
							navigation.navigate('Cart');
						},
					}}>
					Added to cart.
				</Snackbar>
			</View>
		</Page>
	);
}
