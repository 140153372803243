const WP_URL = 'https://wp.enrgstore.com/wp-json';

const wcAPI = {
	categories: `${WP_URL}/wc/v3/products/categories`,
	orders: `${WP_URL}/wc/v3/products/orders`,
	pay: `${WP_URL}/nrgstore/v1/pay`,
	products: `${WP_URL}/wc/v3/products`
};

export {
	wcAPI,
	WP_URL
}
