import * as React from 'react';
import {FlatList, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import { useMediaQuery } from 'react-responsive'

// Local
import Footer from "../components/Footer";
import {Icons, Measurements} from "../constants";
import {CartContext} from "../context";

function Product({image, name, price, quantity}) {
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			padding: 16,
			flexDirection: 'row',
			borderBottomWidth: 1,
			borderBottomColor: "#e9e9e9",
		},
		image: {
			width: 72,
			height: 72,
		},
		info: {
			flex: 1,
			marginLeft: 24,
		},
		name: {
			fontSize: 16,
			color: '#656565'
		},
		price: {
			fontSize: 14,
			fontWeight: '500',
			marginTop: 6,
		},
		quantityContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 16,
			marginBottom: 24,
			borderRadius: 2,
			borderColor: '#e1e1e1',
			borderWidth: 1,
			width: 100
		},
		quantity: {
			fontSize: 15,
		},
		delete: {
			width: 80,
			height: 32,
			backgroundColor: 'red'
		}
	});
	return (
		<View style={styles.container}>
			<Image resizeMode={'contain'} source={image} style={styles.image}/>
			<View style={styles.info}>
				<Text style={styles.name}>{name}</Text>
				<Text style={styles.price}>BBD ${Number(price).toFixed(2)}</Text>
				<Text style={styles.quantity}>Quantity: {quantity}</Text>
			</View>
		</View>
	)
}

const OrderConfirmation = () => {
	const cartContext = React.useContext(CartContext);
	const cartTotal = ()=> {
		let total = 0;
		cartContext.cart.forEach(item => {
			const itemTotal = item.price * item.quantity;
			total = total + itemTotal;
		});
		return total;
	};
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: Measurements.headerHeight,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: '#fff',
		},
		content: {
			width: '100%',
			maxWidth: 1024,
			minHeight: 560,
			flexDirection: isTabletOrMobile ? 'column' : 'row',
			margin: 'auto',
		},
		box: {
			width: isTabletOrMobile ? '100%' : 600,
			paddingHorizontal: 32,
			paddingVertical: 80,
			marginVertical: isTabletOrMobile ? 16 : 80,
			marginHorizontal: isTabletOrMobile ? 16 : 'auto',
			borderRadius: 2,
			borderColor: '#e0e0e0',
			borderWidth: 1,
		},
		confirmationImg: {
			width: 104,
			height: 104,
			marginHorizontal: 'auto',
		},
		confirmationText: {
			marginHorizontal: 'auto',
			fontSize: 18,
			textAlign: 'center',
			marginBottom: 48,
			marginTop: 16,
		},
		list: {
			flex: 1,
			width: '100%',
		},
		orderTotal: {
			marginTop: 16,
			fontSize: 18,
		},
		footer: {
			marginTop: 40,
			marginHorizontal: 'auto',
			fontSize: 16,
			textAlign: 'center',
		}
	});
	return (
		<View style={styles.container}>
			<ScrollView>
				<View style={styles.content}>
					<View style={styles.box}>
						<Image source={{uri: Icons.checkmarkColored}} style={styles.confirmationImg}/>
						<Text style={styles.confirmationText}>Order Successful</Text>
						{/*<FlatList
							style={styles.list}
							data={cartContext.cart}
							keyExtractor={(item, index) => index.toString()}
							renderItem={({item, index}: any) => (
								<Product {...item} index={index}/>
							)}/>
						<Text style={styles.orderTotal}>Order total: BBD ${Number(cartTotal()).toFixed(2)}</Text>*/}
						<Text style={styles.footer}>Please keep a record of this transaction.</Text>
					</View>
				</View>
				<Footer/>
			</ScrollView>
		</View>
	);
};

export default OrderConfirmation;
