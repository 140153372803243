import React from 'react';
import {Image, StyleSheet, View} from "react-native";
import {Measurements} from "../constants";

const Page = ({children}) => {
  const styles = StyleSheet.create({
    container: {
      top: Measurements.headerHeight/2,
      right: 0,
      bottom: 0,
      left: 0,
      position: 'absolute',
    },
    bgImg: {},
    overlay: {
      backgroundColor: 'white',
      opacity: 0.8
    },
    content: {}
  });
  return (
    <View style={styles.container}>
      <Image
        style={[styles.container, styles.bgImg]}
        source={require('../assets/backdrop.png')}
        resizeMode={'repeat'}/>
      <View style={[styles.container, styles.overlay]}/>
      <View style={[styles.container, styles.content]}>
        {children}
      </View>
    </View>
  );
};

export default Page;