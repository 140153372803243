import * as React from 'react';
import {StyleSheet, TouchableOpacity, Text, View} from "react-native";

// Local
import {Colors} from '../constants';

const Button = ({children = '', disabled = false, onPress, style = {}}) => {
	const styles = StyleSheet.create({
		container: {
			padding: 14,
			backgroundColor: disabled ? '#aaaaaa' : Colors.accent,
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 2,
		},
		text: {
			color: '#ffffff',
			fontWeight: '600',
			fontSize: 16,
		}
	});
	return (
		<TouchableOpacity onPress={disabled ? null : onPress}>
			<View style={[styles.container, style]}>
				<Text style={styles.text}>{children.toUpperCase()}</Text>
			</View>
		</TouchableOpacity>
	);
};

export default Button;
