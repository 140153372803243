import {Formik} from 'formik';
import * as React from 'react';
import {AsyncStorage, CheckBox, Linking, Picker, ScrollView, StyleSheet, Text, TextInput, View} from "react-native";
import {ActivityIndicator, Title} from "react-native-paper";
import {useMediaQuery} from 'react-responsive';

// Local
import {Colors, Measurements, Strings} from '../../constants';
import {Countries} from '../../data';
import validationSchema from './validationSchema';
import {wcAPI} from "../../apis/WPREST";
import Button from '../../components/Button';

const makePayment = async (props) => {
	//console.log(props);return false;
	const data = props.values;
	if(data.isSameAddress)
	{
		data.deliveryName = data.name;
		data.deliveryAddress1 = data.address1;
		data.deliveryAddress2 = data.address2;
		data.deliveryCity = data.city;
		data.deliveryState = data.state;
		data.deliveryZip = data.zip;
		//data.deliveryCountry = data.selectedCountry;
	}
	//console.log(data);return false;
	const cart = props.cart;
	const body = {
		...data,
		line_items: cart,
		country: data.selectedCountry.name,
		deliveryCountry: data.isSameAddress? data.selectedCountry.name : data.selectedDeliveryCountry.name,
		cardExp: data.cardMonth + '/' + data.cardYear
	};
	return fetch(wcAPI.pay, {
		method: "POST",
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	})
		.then(results => results.json())
		.then((res) => {
			//console.log('res', res);
			return res;
		});
};

const CheckedItem = (props) => {
	const {isChecked = false, label, onChange} = props;
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
			flexDirection: 'row',
			alignItems: 'center',
			marginRight: 32,
		},
		label: {
			fontSize: 17,
			color: Colors.accent,
			marginLeft: 8,
		},
	});
	return (
		<View style={styles.container}>
			<CheckBox value={isChecked} onChange={onChange}/>
			<Text style={styles.label}>
				{label}
			</Text>
		</View>
	)
};

const Input = ({onChangeText, editable = true, error, label, multiline = false, placeholder, value}) => {
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
		},
		label: {
			fontSize: 18,
			color: '#424242',
		},
		inputContainer: {
			marginTop: 12,
			borderRadius: 4,
			borderColor: error ? 'red' : '#dddddd',
			borderWidth: 1,
			height: multiline ? 128 : 44,
			justifyContent: multiline ? 'flex-start' : 'center',
			padding: 16,
		},
		errorText: {
			color: 'red',
			marginTop: 2
		}
	});
	const allProps = Object.assign({}, {onChangeText, multiline, placeholder, value});
	return (
		<View style={styles.container}>
			<Text style={styles.label}>
				{label}
			</Text>
			<View style={styles.inputContainer}>
				<TextInput {...allProps}/>
			</View>
			{
				error &&
				<Text style={styles.errorText}>
					{error}
				</Text>
			}
		</View>
	)
};

const CountryPicker = ({delivery = false, selectedCountry, selectedDeliveryCountry, setCountry}) => {
	if (delivery) selectedCountry = selectedDeliveryCountry;
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
		},
		label: {
			fontSize: 18,
			color: Colors.accent,
		},
		picker: {
			height: 44,
			marginVertical: 16,
		},
	});
	const pickerCountries = [];
	for (let i = 0; i < Countries.length; i++) {
		const parish = Countries[i];
		pickerCountries.push(
			<Picker.Item key={i} label={parish.name} value={parish.name}/>
		)
	}
	return (
		<View style={styles.container}>
			<Text style={styles.label}>
				Country
			</Text>
			<Picker
				selectedValue={delivery ? selectedDeliveryCountry.name : selectedCountry.name}
				style={styles.picker}
				onValueChange={(itemValue, itemIndex) => {
					const selected = Countries[itemIndex];
					setCountry(Countries[itemIndex])
					//if (delivery) this.setState({selectedDeliveryParish: selectedParish});
					//else setCountry(Countries[itemIndex])
				}}>
				{pickerCountries}
			</Picker>
		</View>
	);
};

const CardForm = ({errors, handleChange, values}) => {
	const styles = StyleSheet.create({
		container: {},
		row: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
		}
	});
	return (
		<View style={styles.container}>
			<Input
				onChangeText={handleChange('name')}
				value={values.name}
				error={errors.name}
				placeholder="Enter your full name"
				label='Name'
			/>
			<Input
				onChangeText={handleChange('cardNumber')}
				value={values.cardNumber}
				error={errors.cardNumber}
				placeholder="Enter your card number"
				label='Card Number'
			/>
			<View style={styles.row}>
				<Input
					onChangeText={handleChange('cardMonth')}
					value={values.cardMonth}
					error={errors.cardMonth}
					placeholder="MM"
					label='Exp Month'
				/>
				<Input
					onChangeText={handleChange('cardYear')}
					value={values.cardYear}
					error={errors.cardYear}
					placeholder="YY"
					label='Exp Year'
				/>
				<Input
					onChangeText={handleChange('cardCode')}
					value={values.cardCode}
					error={errors.cardCode}
					placeholder="Enter CVV/CVC"
					label='Card CVV/CVC'
				/>
			</View>
		</View>
	);
};

const AddressForm = ({errors, handleChange, setFieldValue, isBilling = true, values}) => {
	const styles = StyleSheet.create({
		container: {},
		row: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
		}
	});
	return (
		<View style={styles.container}>
			{!isBilling && (
				<Input
					onChangeText={handleChange('deliveryName')}
					value={values.isSameAddress? values.name : values.deliveryName}
					error={errors.deliveryName}
					placeholder="Enter Name"
					label='Name'
				/>
			)}
			<Input
				onChangeText={handleChange(isBilling ? 'address1' : 'deliveryAddress1')}
				value={isBilling ? values.address1 : values.isSameAddress? values.address1 : values.deliveryAddress1}
				error={isBilling ? errors.address1 : errors.deliveryAddress1}
				placeholder="Enter billing address"
				label='Address'
			/>
			<Input
				onChangeText={handleChange(isBilling ? 'address2' : 'deliveryAddress2')}
				value={isBilling ? values.address2 : values.isSameAddress? values.address2 : values.deliveryAddress2}
				error={isBilling ? errors.address2 : errors.deliveryAddress2}
				placeholder=""
				label='Address (Line 2)'
			/>
			<View style={styles.row}>
				<Input
					onChangeText={handleChange(isBilling? 'city' : 'deliveryCity')}
					value={isBilling ? values.city : values.isSameAddress? values.city : values.deliveryCity}
					error={isBilling ? errors.city : errors.deliveryCity}
					placeholder=""
					label='City'
				/>
				<Input
					onChangeText={handleChange(isBilling? 'state' : 'deliveryState')}
					value={isBilling ? values.state : values.isSameAddress? values.state : values.deliveryState}
					error={isBilling ? errors.state : errors.deliveryState}
					placeholder=""
					label='State/Province/Parish'
				/>
			</View>
			<View style={styles.row}>
				<Input
					onChangeText={handleChange(isBilling? 'zip' : 'deliveryZip')}
					value={isBilling ? values.zip : values.isSameAddress? values.zip : values.deliveryZip}
					error={isBilling ? errors.zip : errors.deliveryZip}
					placeholder=""
					label='Zip Code'
				/>
				{
					isBilling ? (
						<CountryPicker
							setCountry={(country) => setFieldValue('selectedCountry', country)}
							selectedCountry={values.selectedCountry}
							selectedDeliveryCountry={values.selectedDeliveryCountry}/>
					) : (
						<CountryPicker
							setCountry={(country) => setFieldValue('selectedDeliveryCountry', country)}
							selectedCountry={values.selectedCountry}
							selectedDeliveryCountry={values.selectedDeliveryCountry}
							delivery={!values.isSameAddress}/>
					)
				}
			</View>
			{isBilling && (
				<Input
					onChangeText={handleChange('email')}
					value={values.email}
					error={errors.email}
					placeholder="Enter your email address"
					label='Email Address'
				/>
			)}
			<Input
				onChangeText={handleChange(isBilling? 'number' : 'deliveryNumber')}
				value={isBilling ? values.number : values.isSameAddress? values.number : values.deliveryNumber}
				error={isBilling ? errors.number : errors.deliveryNumber}
				placeholder="Enter your telephone number"
				label='Telephone Number'
			/>
		</View>
	)
};

const Checkout = ({cart, cartTotal, navigation, dismiss, updateCart}) => {
	const [termsAccepted, acceptTerms] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const isTabletOrMobile = useMediaQuery({query: `(max-width: ${Measurements.tabletBreakPt}px)`});
	const contentWidth = 1024;
	const moreThan600 = contentWidth > 600;
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: Measurements.headerHeight,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: '#fff',
		},
		content: {
			width: '100%',
			maxWidth: 1024,
			minHeight: 300,
			height: '100%',
			flexDirection: isTabletOrMobile ? 'column' : 'row',
			margin: 'auto',
			paddingHorizontal: 16,
		},
		form: {
			flex: 1,
			width: '100%',
			paddingBottom: 56,
			paddingHorizontal: isTabletOrMobile ? 16 : 0,
			marginHorizontal: 'auto',
		},
		title: {
			color: Colors.accent,
			marginTop: 32,
		},
		subtext: {
			textAlign: 'center',
			marginTop: 12,
			color: Colors.accent,
		},
		checkGroup: {
			flexDirection: moreThan600 ? 'row' : 'column',
		},
		submitContainer: {
			alignSelf: 'center',
			alignItems: 'center',
		},
		icon: {
			height: 96,
			width: 96,
			alignSelf: 'center',
			marginTop: 16,
		},
		summary: {
			width: isTabletOrMobile ? '100%' : 300,
			height: 284,
			padding: 16,
			borderWidth: 1,
			borderColor: "#e9e9e9",
			borderRadius: 2,
			marginLeft: isTabletOrMobile ? 0 : 32,
			marginTop: isTabletOrMobile ? 0 : 24,
		},
		summaryRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginVertical: 6,
		},
		summaryText: {},
		summaryValue: {
			fontWeight: '500'
		},
		summaryFinalValue: {
			fontWeight: '700'
		},
		disclaimerContainer: {
			width: '100%',
			marginVertical: 16,
			flexDirection: 'row',
		},
		disclaimer: {
			marginLeft: 8,
		},
		disclaimerLink: {
			color: Colors.accent
		}
	});
	const Summary = ({handleSubmit}) => (
		<View style={styles.summary}>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Subtotal:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(cartTotal).toFixed(2)}</Text>
			</View>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Delivery:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(0).toFixed(2)}</Text>
			</View>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Tax:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(0).toFixed(2)}</Text>
			</View>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Total:</Text>
				<Text style={styles.summaryFinalValue}>BBD ${Number(cartTotal).toFixed(2)}</Text>
			</View>
			<View style={{height: 16}}/>
			{
				loading?
					<ActivityIndicator size={'small'}/>
				:
					<Button
						disabled={loading || !termsAccepted}
						onPress={() => {
							handleSubmit()//.then(()=>acceptTerms(true));
						}}>
						Pay now
					</Button>
			}
			<View style={styles.disclaimerContainer}>
				<CheckBox value={termsAccepted} onValueChange={(value) => acceptTerms(value)}/>
				<Text style={styles.disclaimer}>
					By placing your order, you agree to our
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.refundUrl)}>
						{' '}Returns Policy
					</Text>
					,
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.privacyUrl)}>
						{' '}Privacy Policy{' '}
					</Text>
					and
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.termsUrl)}>
						{' '}Terms & Conditions
					</Text>
					.
				</Text>
			</View>
		</View>
	);

	return (
		<Formik
			initialValues={{
				// Card Info
				name: '',// 'cardtest',
				cardNumber: '',// '4111111111111111',
				cardMonth: '',// '12',
				cardYear: '',// '20',
				cardCode: '',// '123',
				// Billing Address
				address1: '',// 'the first address',
				address2: '',// 'the second address',
				city: '',// 'in the city',
				state: '',// 'St. Michael',
				zip: '',// '246',
				selectedCountry: Countries[19],
				email: '',// 'tafarrygoodman@taylord.tech',
				number: '',// '2341234567',
				isSameAddress: true,
				// Delivery Address
				deliveryName: '',// 'cardtest',
				deliveryAddress: '',// 'address 1',
				deliveryAddress1: '',// 'address 1',
				deliveryAddress2: '',// 'address 2',
				deliveryCity: '',// 'In the city',
				deliveryState: '',// 'St. Michael',
				deliveryZip: '',// '246',
				deliveryNumber: '',// '2461234567',
				selectedDeliveryCountry: Countries[19],
			}}
			onSubmit={(values,actions) => {
				setLoading(true);
				//console.log('cart', cart);
				return makePayment({values, cart: cart})
					.then(async (res) => {
						if (res == 'success') {
							await AsyncStorage.setItem('@cart', JSON.stringify([]));
							updateCart({type: 'init', payload: {cart: []}});
							dismiss();
							navigation.navigate('OrderConfirmation', {id: ''});
						} else {
							//console.log('res', res);
							setLoading(false);
							if(res.code === 'badcard' || res.code === 'fraudDetected' || res.code === 'problem') actions.setFieldError('cardNumber',res.message);
							else actions.setFieldError(res.code,res.message);
							alert("Checkout failed");
						}
					})
					.catch((e) => {
						//console.error(e);
						if(e.code === 'badcard' || e.code === 'fraudDetected' || e.code === 'problem') actions.setFieldError('cardNumber',e.message);
						else actions.setFieldError(e.code,e.message);
						setLoading(false);
						alert("Checkout failed");
					});
			}}
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({handleChange, handleSubmit, values, errors, setFieldValue}) => (
				<View style={styles.container}>
					<View style={styles.content}>
						<ScrollView
							showsVerticalScrollIndicator={false}>
							<View style={styles.form}>
								<View>
									<Title style={styles.title}>Credit Card</Title>
									<CardForm
										errors={errors}
										handleChange={handleChange}
										values={values}/>
									<Title style={styles.title}>Billing Address</Title>
									<AddressForm
										errors={errors}
										handleChange={handleChange}
										values={values}
										setFieldValue={setFieldValue}/>
									<Title style={styles.title}>Delivery Address</Title>
									<CheckedItem
										isChecked={values.isSameAddress}
										label='Same as Billing Address'
										onChange={() => {
											setFieldValue('isSameAddress', !values.isSameAddress);
										}}/>
									{!values.isSameAddress && (
										<AddressForm
											isBilling={false}
											errors={errors}
											handleChange={handleChange}
											values={values}
											setFieldValue={setFieldValue}/>
									)}
								</View>
							</View>
							{isTabletOrMobile && <Summary handleSubmit={handleSubmit}/>}
						</ScrollView>
						{!isTabletOrMobile && <Summary handleSubmit={handleSubmit}/>}
					</View>
				</View>
			)}
		</Formik>
	)
};

export default Checkout;
