import * as React from 'react';
import {AsyncStorage, StyleSheet, View} from 'react-native';
import {DefaultTheme, Provider as PaperProvider} from "react-native-paper";

// Local
import Navigator from './navigation/Navigator';
import {CartContext} from "./context";
import {CartReducer} from "./reducers";
import Loader from './components/Loader';
import {Colors} from "./constants";

const theme = {
	...DefaultTheme,
	roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: Colors.primary,
		accent: Colors.accent,
	},
};


export default function App() {
	const [isReady, setIsReady] = React.useState(false);
	const [state, dispatch] = React.useReducer(CartReducer, {cart: []});
	const styles = StyleSheet.create({
		container: {
			flex: 1,
			backgroundColor: '#fff',
		},
	});
	
	React.useEffect(() => {
		AsyncStorage.getItem('@cart').then((value) => {
			if (value) {
				dispatch({type: 'init', payload: {cart: JSON.parse(value)}});
			}
			setIsReady(true);
		});
	}, []);
	
	return (
		<PaperProvider theme={theme}>
			{isReady ? (
				<CartContext.Provider
					value={{
						cart: state.cart,
						updateCart: dispatch,
						orderId: 0,
						updateOrderId:()=>{}
					}}>
					<View style={styles.container}>
						<Navigator/>
					</View>
				</CartContext.Provider>
			) : (
				<Loader/>
			)}
		</PaperProvider>
	);
}
