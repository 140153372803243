import * as React from 'react';
import {AsyncStorage, FlatList, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import {Card, Divider, IconButton, Modal, Portal} from "react-native-paper";
import { useMediaQuery } from 'react-responsive'

// Local
import Button from "../../components/Button";
import {CartContext} from "../../context";
import Footer from "../../components/Footer";
import {Measurements} from "../../constants";
import Checkout from "./Checkout";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import Page from "../../components/Page";

function CheckoutForm({products,subtotal}) {
	return(
		<form method="post" action="https://pay1.plugnpay.com/payment/pay.cgi">
			<input type="hidden" name="publisher-name" value="theenergys"/>
			<input type="hidden" name="card-allowed" value="visa,mastercard"/>
			<input type="hidden" name="checktype" value="WEB"/>
			<input type="hidden" name="showcompany" value="no"/>
			<input type="hidden" name="shipinfo" value="0"/>
			<input type="hidden" name="easycart" value="1"/>
			<input type="hidden" name="currency" value="bbd"/>
			<input type="hidden" name="subtotal" value={subtotal}/>
			<input type="hidden" name="receipt_type" value="itemized"/>
			<input type="hidden" name="customname1" value="orderDate"/>
			<input type="hidden" name="customvalue1" value={Date()}/>
			{
				products && products.map((product,index)=>{
					index++;
					return (
						<div>
						<input type="hidden" name={"item"+index} value={"item"+index}/>
						<input type="hidden" name={"description"+index} value={product.name}/>
						<input type="hidden" name={"cost"+index} value={product.price}/>
						<input type="hidden" name={"quantity"+index} value={product.quantity}/>
						</div>
				);
				})
			}
			<input type="submit" value="Checkout Now" className="submit"/>
		</form>
	);
}

async function createOrder(cart,updateOrderId){
	const consumerKey = 'ck_d3b055834dbe6322778dfaaba171fef359006526';
	const consumerSecret = 'cs_dadd6535df9b8f91a2bdf4b56a593fcee004a141';
	let line_items = [];
	for(let i=0;i<cart.length;i++){
		line_items.push({product_id:cart[i]['id'],quantity:cart[i]['quantity']});
	}
	let body = {
		line_items
	};
	const WooCommerce = new WooCommerceRestApi({
		url:'https://wp.enrgstore.com',
		consumerKey,
		consumerSecret,
		version: 'wc/v3',
		queryStringAuth: true
	});
	return WooCommerce.post('orders',body)
		.then((response)=>{
			console.log('response',response);
			let orderId = response.data.id;
			updateOrderId({type: 'string', payload: orderId});
			return true;
		});
}

function Product({cart, id, image, index, name, price, quantity, updateCart}) {
	const item = {name, image, price, quantity, id};
	const updateQuantity = (increase = true)=> {
		const newItem = {...item};
		if (increase){
			newItem.quantity = item.quantity + 1;
		} else {
			if (item.quantity >= 2){
				newItem.quantity = item.quantity - 1;
			}
		}
		const newCart = [...cart];
		newCart[index] = newItem;
		AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(()=> {
			updateCart({type: 'init', payload: {cart: newCart}});
		});
	};
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			padding: 16,
			flexDirection: 'row',
			borderBottomWidth: 1,
			borderBottomColor: "#e9e9e9",
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			borderWidth: 1,
			borderColor: '#e0e0e0',
			borderRadius: 4,
			marginBottom: 24
		},
		image: {
			width: 120,
			height: 120,
		},
		info: {
			flex: 1,
			marginLeft: 24,
		},
		name: {
			fontSize: 22,
			color: '#656565'
		},
		price: {
			fontSize: 18,
			fontWeight: '600',
			marginTop: 8,
		},
		quantityContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 16,
			marginBottom: 24,
			borderRadius: 2,
			borderColor: '#e1e1e1',
			borderWidth: 1,
			width: 100
		},
		quantity: {
			fontSize: 15,
			fontWeight: '500',
			marginHorizontal: 6,
		},
		delete: {
			width: 80,
			height: 32,
			backgroundColor: 'red'
		}
	});
	return (
		<View style={styles.container}>
			<Image resizeMode={'contain'} source={image} style={styles.image}/>
			<View style={styles.info}>
				<Text style={styles.name}>{name}</Text>
				<Text style={styles.price}>BBD ${Number(price).toFixed(2)}</Text>
				<View style={styles.quantityContainer}>
					<IconButton icon={'minus'} size={18} color={'#757575'} onPress={()=> updateQuantity(false)}/>
					<Text style={styles.quantity}>{quantity}</Text>
					<IconButton icon={'plus'} size={18} color={'#757575'} onPress={()=> updateQuantity()}/>
				</View>
				<Button
					style={styles.delete}
					onPress={()=> {
						const newCart = [...cart];
						newCart.splice(index, 1);
						AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(()=> {
							updateCart({type: 'init', payload: {cart: newCart}});
							alert(`${name} has been deleted from your cart. Close to continue shopping`);
						});
					}}>
					Delete
				</Button>
			</View>
		</View>
	)
}

export default function Cart({navigation}) {
	const [modalVisible, showModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const cartContext = React.useContext(CartContext);
	const cartHasItems = cartContext.cart.length > 0;
	const cartTotal = ()=> {
		if (!cartHasItems) return 0;
		let total = 0;
		cartContext.cart.forEach(item => {
			const itemTotal = item.price * item.quantity;
			total = total + itemTotal;
		});
		return total;
	};
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
		},
		content: {
			width: '100%',
			maxWidth: 1024,
			minHeight: 560,
			flexDirection: isTabletOrMobile ? 'column' : 'row',
			margin: 'auto',
		},
		list: {
			flex: 1,
			width: '100%',
		},
		listEmptyText: {
			fontSize: 16,
			textAlign: 'center',
			marginTop: isTabletOrMobile ? 80 : 0
		},
		summary: {
			minWidth: 300,
			height: 112,
			padding: 16,
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			borderWidth: 1,
			borderColor: '#e0e0e0',
			borderRadius: 4,
			marginLeft: isTabletOrMobile ? 0 : 16
		},
		summaryRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		summaryText: {},
		summaryValue: {
			fontWeight: '600'
		},
		modal: {
			flex: 1,
			padding: isTabletOrMobile ? 0 : 24,
		},
		modalContent: {
			flex: 1,
			backgroundColor: 'white',
			borderRadius: 2,
		}
	});
	const Summary = ()=> (
		<View style={styles.summary}>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Subtotal:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(cartTotal()).toFixed(2)}</Text>
			</View>
			<View style={{height: 16}}/>
			<Button disabled={loading} onPress={()=> {
				//setLoading(true);
				//createOrder(cartContext.cart,cartContext.updateOrderId).then(()=>{setLoading(false);showModal(true);}).catch((error)=>{setLoading(false)});
				showModal(true);
			}}>
				Checkout now
			</Button>
			{/*<CheckoutForm products={cartContext.cart} subtotal={Number(cartTotal()).toFixed(2)} />*/}
			<Portal>
				<Modal
					contentContainerStyle={styles.modal}
					visible={modalVisible}
					onDismiss={()=> showModal(false)}>
					<View style={styles.modalContent}>
						{ isTabletOrMobile ? (
							<Card.Title
								title={'Checkout'}
								subtitle={`BBD $${Number(cartTotal()).toFixed(2)}`}
								right={()=> <IconButton onPress={()=> showModal(false)} icon={'close'}/>}/>
						) : (
							<Card.Title
								title={'Checkout'}
								left={()=> <IconButton onPress={()=> showModal(false)} icon={'close'}/>}/>
						)}
						<Divider/>
						<Checkout
							cart={cartContext.cart}
							cartTotal={cartTotal()}
							navigation={navigation}
							updateCart={cartContext.updateCart}
							dismiss={()=> showModal(false)}/>
					</View>
				</Modal>
			</Portal>
		</View>
	);
	return (
		<Page>
			<View style={styles.container}>
				<ScrollView contentContainerStyle={{paddingTop: isTabletOrMobile ? 0 : 80}}>
					<View style={styles.content}>
						{isTabletOrMobile && cartHasItems && <Summary/>}
						<FlatList
							ListEmptyComponent={
								<Text style={styles.listEmptyText}>Your cart is empty. Start shopping today!</Text>
							}
							style={styles.list}
							data={cartContext.cart}
							keyExtractor={(item, index) => index.toString()}
							renderItem={({item, index}: any) => (
								<Product {...item} index={index} updateCart={cartContext.updateCart} cart={cartContext.cart}/>
							)}/>
						{!isTabletOrMobile && cartHasItems && <Summary/>}
					</View>
					<Footer/>
				</ScrollView>
			</View>
		</Page>
	);
}
