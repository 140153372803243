export const sanitizeHtml = (html)=> {
	//-- remove BR tags and replace them with line break
	html=html.replace(/<br>/gi, "");
	html=html.replace(/<br\s\/>/gi, "");
	html=html.replace(/<br\/>/gi, "");
	
	//-- remove P and A tags but preserve what's inside of them
	html=html.replace(/<p.*?>/gi, "\n");
	html=html.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 ($1)");
	
	//-- remove all inside SCRIPT and STYLE tags
	html=html.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "");
	html=html.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "");
	//-- remove all else
	html=html.replace(/<(?:.|\s)*?>/g, "");
	
	//-- get rid of more than 2 multiple line breaks:
	html=html.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n");
	
	//-- get rid of more than 2 spaces:
	html = html.replace(/ +(?= )/g,'');
	
	//-- get rid of html-encoded characters:
	html=html.replace(/&nbsp;/gi," ");
	html=html.replace(/&amp;/gi,"&");
	html=html.replace(/&quot;/gi,'"');
	html=html.replace(/&lt;/gi,'<');
	html=html.replace(/&gt;/gi,'>');
	html=html.replace(/&#x2013;/gi,'-');
	html=html.replace(/&#x2019;/gi,"'");
	html=html.replace(/&#xA9;/gi,"Copyright");
	html=html.replace(/&reg;/gi," (Registered)");
	
	return html;
};
