export const CartReducer = (state, action)=> {
	const {cart: currentCart} = state;
	const {payload, type} = action;
	switch (type) {
		case 'init': {
			return {cart: payload.cart};
		}
		case 'add': {
			return {cart: [...currentCart, payload.item]};
		}
		case 'delete': {
			return {cart: []};
		}
		case 'removeAll': {
			return {cart: []};
		}
		default:
			return {...state};
	}
};
