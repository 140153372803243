import * as React from "react";
import {SceneView} from "@react-navigation/core";
import {Image, StyleSheet, Text, View} from 'react-native';
import { useMediaQuery } from 'react-responsive'

// Local
import Link from "../components/Link";
import {Colors, Measurements} from "../constants";
import {CartContext} from "../context";

function Header({navigation}) {
	const cartContext = React.useContext(CartContext);
	const cartCount = cartContext.cart.length;
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			backgroundColor: '#fff',
			flexDirection: 'row',
			width: '100%',
			height: Measurements.headerHeight,
			justifyContent: 'space-between',
			alignItems: 'center',
			borderBottomWidth: 1,
			borderBottomColor: "#e9e9e9",
			paddingHorizontal: isTabletOrMobile ? 24 : 64,
		},
		logo: {
			height: isTabletOrMobile ? 40 : 56,
			width: isTabletOrMobile ? 40 : 320,
		},
		linksContainer: {
			flexDirection: 'row',
		},
		link: {
			paddingHorizontal: 12,
		},
		linkText: {
			fontSize: 16,
			fontWeight: '500'
		},
		cartCount: {
			fontWeight: cartCount === 0 ? '500' : '800',
			color: cartCount === 0 ? '#000000' : Colors.accent
		}
	});
	return (
		<View style={styles.container}>
			<Link routeName={"Home"} navigation={navigation}>
				<Image
					style={styles.logo}
					source={
						isTabletOrMobile ?
							require('../assets/logo-short.png'):
						require('../assets/logo-long.png')
					}/>
			</Link>
			<View style={styles.linksContainer}>
				<View style={styles.link}>
					<Link routeName={"Home"} navigation={navigation}>
						<Text style={styles.linkText}>Home</Text>
					</Link>
				</View>
				<View style={styles.link}>
					<Link routeName={"Cart"} navigation={navigation}>
						<Text style={styles.linkText}>Cart <Text style={styles.cartCount}>({cartCount})</Text></Text>
					</Link>
				</View>
			</View>
		</View>
	);
}

export default function AppView({descriptors, navigation}) {
	const activeKey = navigation.state.routes[navigation.state.index].key;
	const descriptor = descriptors[activeKey];
	return (
		<div style={{height: "100%"}}>
			<Header navigation={navigation}/>
			<div>
				<SceneView
					navigation={descriptor.navigation}
					component={descriptor.getComponent()}
				/>
			</div>
		</div>
	);
}
