import React from 'react';
import {
  View
} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';

const Loader = () => {
  const styles = {
    container: {
      width: "100%",
      padding: 120,
    }
  };
  return (
    <View style={styles.container}>
      <ActivityIndicator size={'large'}/>
    </View>
  );
};

export default Loader;
