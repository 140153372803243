import * as yup from 'yup';

const validationSchema = yup.object().shape({
	name: yup.string()
		.trim()
		.required('Please enter name.'),
	cardNumber: yup.string()
		.trim()
		.matches(/^[0-9]{16}/, 'Please enter a valid 16-digit card number.')
		.required('Please enter your card number.'),
	cardMonth: yup.string()
		.trim()
		.matches(/0[1-9]|1[0-2]/, 'Please enter a valid month.')
		.required('Please enter a valid month.'),
	// up to five years after this year, and from five years to this year
	cardYear: yup.number()
		.lessThan(parseInt(((new Date().getFullYear() + 6).toString().substring(2, 4)), 10), "Please enter a valid year.")
		.moreThan(parseInt(((new Date().getFullYear() - 6).toString().substring(2, 4)), 10), "Please enter a valid year.")
		.required('Please enter a valid year.'),
	cardCode: yup.string()
		.trim()
		.matches(/^[0-9]{3}$/, 'Please enter a valid 3-digit card code.')
		.required('Please enter a valid 3-digit card code.'),
	address1: yup.string()
		.trim()
		.required('Please enter your address.'),
	address2: yup.string()
		.trim(),
	city: yup.string()
		.trim()
		.required('Please enter your city.'),
	state: yup.string()
		.trim()
		.required('Please enter your state.'),
	zip: yup.string()
		.trim()
		.required('Please enter your zip code.'),
	selectedCountry: yup.string()
		.required('Please select your country.'),
	email: yup.string()
		.trim()
		.matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, "Please enter a valid email address.")
		.required("Please enter your email."),
	number: yup.string()
		.matches(/^[0-9]{10}/, 'Please enter your 10 digit number.')
		.required('Please enter your number.'),
	isSameAddress: yup.boolean(),
	deliveryName: yup.string()
		.when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s name.')
	}),
	/*deliveryAddress: yup.string()
		.required('Please enter the recipient\'s address.'),*/
	deliveryAddress1: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s address.'),
	}),
	deliveryAddress2: yup.string(),
	deliveryCity: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s city .'),
	}),
	deliveryState: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s state.')
	}),
	deliveryZip: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s zip code.')
	}),
	deliveryNumber: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s number.')
	}),
	selectedDeliveryCountry: yup.string().when('isSameAddress',{
		is:false,
		then:yup.string().required('Please enter the recipient\'s country of residency.')
	})
});

export default validationSchema;
