import * as React from "react";

interface CartProps {
	cart: any,
	updateCart: any,
	orderId: any,
	updateOrderId: any
}

const CartContext = React.createContext<CartProps>({
	cart: [],
	orderId: 0,
	updateCart: ()=> {},
	updateOrderId:()=>{}
});

export {CartContext};
